@app-main-color: #4e4353;

.table-narrow {
	margin: 0 auto;
}
.table-narrow > tbody > tr > td {
	padding: 3px;
}
.table-narrow-bordered > tbody > tr:not(:last-child) > td {
	border-bottom: 1px solid #ccc;
}
.chosen-container-multi ul.chosen-choices {
	height: auto;
}
.dashboard-project-item {
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-top: 3px;
  margin-right: 3px;
  border: 1px solid grey;
  position: relative;
}
.dashboard-project-item:hover {
    border-color: #ff902b;
    border-width: 2px;
}
.dashboard-project-item div {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}
.dashboard-project-item-overdue {
  border-color: #f05050;
  color: #f05050;
}
.dashboard-project-item-initial {
  background-color: #ffe72b;
}
.dashboard-project-item-final {
  border-color: #27c24c;
  background-color: #27c24c;
  color: #27c24c;
}
.scroll-400 {
    max-height: 400px;
    overflow-y: auto;
}

.offsidebar {
  display: none;
}

.offsidebar-open .offsidebar {
  display: block;
}

.py-1 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mb-0 {
  margin-bottom: 0;
}

.table-responsive {
  overflow-x: unset !important;
}
