/*!
 * table-grid (http://mdo.github.io/table-grid)
 * Released under MIT, (c) 2014 Mark Otto
 */

/*
 * The Grid
 */

@media @tablet {
  /* Add `.table-grid` for the table */
  .table-grid {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
  }

  /* Add `.col` for the table cells, or columns */
  .col {
    display: table-cell;
    vertical-align: top;
    height: 100%;
    &.col-full-center {
      vertical-align: middle;
    }
  }

  /* Set the widths */
  .col-1 { width: 8.333333%; }
  .col-2 { width: 16.666667%; }
  .col-3 { width: 25%; }
  .col-4 { width: 33.333333%; }
  .col-5 { width: 41.666667%; }
  .col-6 { width: 50%; }
  .col-7 { width: 58.333333%; }
  .col-8 { width: 66.666667%; }
  .col-9 { width: 75%; }
  .col-10 { width: 83.333333%; }
  .col-11 { width: 91.666667%; }
  .col-12 { width: 100%; }

  .col-xxs  {  width: @wd-xxs;  }
  .col-xs   {  width: @wd-xs;   }
  .col-sm   {  width: @wd-sm;   }
  .col-sd   {  width: @wd-sd;   }
  .col-md   {  width: @wd-md;   }
  .col-lg   {  width: @wd-lg;   }
  .col-xl   {  width: @wd-xl;   }
  .col-xxl  {  width: @wd-xxl;  }
  .col-wide {  width: @wd-wide; }
  .col-auto {  width: @wd-auto; }

  /* Padded columns */
  .table-grid-padded {
    margin-left:  -1rem;
    margin-right: -1rem;
  }
  .table-grid-padded .table-grid {
    border-spacing: 1rem 0;
  }

}

@media @upto-desktop {
  .table-grid-desktop {
    &.table-grid,
    .col {
      display: block;
      width: auto;
    }
  }
}

/*
 * Vertically center grid content
 *
 * Requires content within the column to be inline or inline-block.
 */

.table-grid-align-middle .col {
  vertical-align: middle;
}

